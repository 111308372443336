.root {
    /* Dimensions */
    width: 100%;
    height: auto;
    display: flex;
    flex: 1;
    flex-direction: column;
    flex-grow: 1;

    @media (--viewportMedium) {
        padding-top: 2px;
    }
}

.error {
    color: var(--failColor);
}

.priceInput {
    flex-shrink: 0;
    width: 100%;
}
.priceInput > label {
    font-weight: var(--fontWeightSemiBold);
    font-size: 16px;
}

.submitButton {
    margin-top: 32px;
    margin-bottom: 24px;

    @media (--viewportLarge) {
        display: inline-block;
        width: 241px;
        margin-top: 32px;
    }
}

.instantBookingOptions {
    margin-top: 30px;
}

.instantBookingOptions > label {
    font-weight: var(--fontWeightSemiBold);
    font-size: 16px;
}

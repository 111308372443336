@import '../../styles/propertySets.css';

.root {
    @media (--viewportMedium) {
        margin: 79px 40px 40px 20px;
        border: 1px solid #dddddd;
        box-shadow: 0px 6px 16px rgba(0, 0, 0, 0.12);
        border-radius: 40px;
        padding: 40px;
    }
}

/* Booking form inside modalContainer needs special handling */
/* since "inMobile" breakpoint is actually --viewportLarge */
.modalContainer {
    @apply --marketplaceModalInMobileBaseStyles;

    margin-right: 20px;
    overflow-y: scroll;

    @media (--viewportMedium) {
        flex-basis: 576px;
        /* padding      : var(--modalPaddingMedium); */
        background-color: var(--matterColorLight);
        margin-top: 12.5vh;
        margin-bottom: 12.5vh;
    }

    @media (--viewportLarge) {
        padding-left: 20px;
        background-color: transparent;
        margin-top: 0;
        margin-bottom: 0;
        overflow-y: hidden;
        margin-right: 20px;
    }
}

.modalHeading {
    margin-top: 89px;
    margin-bottom: 36px;
    padding: 0 24px;

    @media (--viewportMedium) {
        padding: 0;
    }

    @media (--viewportLarge) {
        display: none;
    }
}

.title {
    /* Font */
    @apply --marketplaceH1FontStyles;
    color: var(--matterColor);

    /* Layout */
    width: 100%;
    margin-top: 0;
    margin-bottom: 0;

    @media (--viewportMedium) {
        margin-top: 0;
        margin-bottom: 5px;
    }
}

.author {
    width: 100%;
    @apply --marketplaceH4FontStyles;
    margin-top: 7px;
    margin-bottom: 0;

    @media (--viewportMedium) {
        margin-top: 0;
        margin-bottom: 0;
        color: var(--matterColor);
    }
}

.bookingHeading {
    display: none;

    @media (--viewportLarge) {
        display: block;
        margin-bottom: 30px;
    }
}

.desktopPriceContainer {
    display: none;

    @media (--viewportLarge) {
        /* Layout */
        display: flex;
        flex-direction: row;
        align-items: flex-end;
        flex-shrink: 0;
        margin: 0 48px 0 0;
        padding: 0;
    }
}

.desktopPriceValue {
    /* Font */
    @apply --marketplaceH2FontStyles;
    color: var(--marketplaceColor);

    @media (--viewportMedium) {
        margin-top: 0;
        margin-bottom: 6px;
    }
}

.desktopPerUnit {
    /* Font */
    @apply --marketplaceH5FontStyles;
    color: var(--matterColor);

    font-family: Poppins;
    font-style: normal;
    font-weight: normal;
    font-size: 16px;

    @media (--viewportMedium) {
        margin-top: 0;
        margin-bottom: 12px;
        margin-left: 6px;
    }
}

.bookingTitle {
    /* Font */
    @apply --marketplaceH3FontStyles;
    color: var(--matterColor);

    margin-top: 0;
    margin-bottom: 2px;
}

.bookingHelp {
    display: none;
    @apply --marketplaceH5FontStyles;

    @media (--viewportMedium) {
        color: var(--matterColor);
        display: block;
        margin-top: 0;
    }
}

.bookingPanelContainer {
    /* margin: 79px 40px 40px 20px;
  border: 1px solid #DDDDDD;
  box-shadow: 0px 6px 16px rgba(0, 0, 0, 0.12);
  border-radius: 40px;
  padding: 40px; */
}

.reportListingContainer {
    @apply --marketplaceTinyFontStyles;
    display: flex;
    flex-direction: column;
    align-items: center;
    text-decoration: none;
}

.bookingForm {
    flex-grow: 1;
    display: flex;
    flex-direction: column;
    /* Mobile phones introduce bottom-bar, which takes 102px vertical space.
     In addition to that we have fixed button that takes 84px vertical space.
  */
    /* margin     : 0 0 186px 0; */

    @media (--viewportMedium) {
        margin: 0;
        min-height: 400px;
    }

    @media (--viewportLarge) {
        min-width: 312px;
        min-height: auto;
    }
}

.submitButtonWrapper {
    flex-shrink: 0;
    padding: 0 24px 24px 24px;
    width: 100%;
    position: fixed;
    bottom: 0;
    background-color: var(--matterColorLight);

    @media (--viewportMedium) {
        padding: 0;
        width: 100%;
        position: static;
        bottom: unset;
        background-color: transparent;
    }
}

.openBookingForm {
    /* Ensure that mobile button is over Footer too */
    z-index: 9;
    position: fixed;
    bottom: 0;
    left: 0;
    width: 100vw;
    padding: 18px 24px 18px 16px;
    background-color: var(--matterColorLight);
    box-shadow: var(--boxShadowTop);
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;

    /* Contain repainting to this component only */
    /* 3D painting container helps scrolling */
    transform: translate3d(0, 0, 0);

    @media (--viewportMedium) {
        padding: 18px 60px 18px 60px;
    }

    @media (--viewportLarge) {
        display: none;
    }
}

.priceContainer {
    /* Layout */
    display: flex;
    flex-direction: column;
    align-items: center;
    flex-shrink: 0;
    margin-right: 22px;
    padding: 5px 12px;
}

.priceValue {
    /* Font */
    @apply --marketplaceH2FontStyles;
    color: var(--matterColor);

    margin-top: 0;
    margin-bottom: 0px;

    @media (--viewportMedium) {
        margin-top: 0;
        margin-bottom: 0;
    }
}

.perUnit {
    /* Font */
    @apply --marketplaceTinyFontStyles;
    color: var(--matterColor);

    margin-top: 0;
    margin-bottom: 0px;

    @media (--viewportMedium) {
        margin-top: 0;
        margin-bottom: 0;
    }
}

.bookButton {
    @apply --marketplaceButtonStylesPrimary;
    min-width: 200px;
    /* Clear padding that is set for link elements looking like buttons */
    padding: 0;
}

.closedListingButton {
    border-left: 1px solid var(--matterColorNegative);
    width: 100%;
    padding: 15px 24px 15px 24px;
    text-align: center;
}

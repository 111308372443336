.root {
    display: flex;
    align-self: center;
}

.bookingInfo {
    display: flex;
    align-items: center;
    flex-wrap: wrap;
}

.dateSection {
    margin-right: 5px;
    color: var(--matterColor);
}

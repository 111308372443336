@import '../../styles/propertySets.css';

.root {
    position: relative;
    display: flex;
}

.icon {
    display: flex;
    width: 24px;
    align-self: stretch;
    border-bottom: 2px solid var(--marketplaceColor);
    background-color: var(--matterColorLight);
}

.iconSvg {
    margin: auto;
}

.iconSvgGroup {
    stroke: var(--marketplaceColor);
    stroke-width: 2.5px;

    @media (--viewportMedium) {
        stroke-width: 2px;
    }
}

.iconSpinner {
    margin: auto;
    width: 23px;
}

.input {
    flex-grow: 1;
    height: var(--LocationAutocompleteInput_inputHeight);
    padding-left: 10px;
    margin: 0;
    line-height: unset;
    border-bottom-width: 2px;

    /* Safari bugfix: without this Safari will print placeholder to a wrong place */
    &::-webkit-input-placeholder {
        line-height: normal;
    }

    &:hover,
    &:focus {
        border-bottom-color: var(--marketplaceColor);
        outline: none;
    }
}

/*
Predictions container can be overriden with new container styles for
size and position, etc.
*/
.predictionsRoot {
    position: absolute;
    width: 100%;
    padding-bottom: var(--locationAutocompleteBottomPadding);
    top: var(--LocationAutocompleteInput_inputHeight);
    left: 0;
    background-color: var(--marketplaceColor);
    border-bottom-left-radius: var(--borderRadius);
    border-bottom-right-radius: var(--borderRadius);
    box-shadow: var(--boxShadowPopup);
    z-index: calc(var(--zIndexPopup) + 1);
}

/*
The Google Maps API TOS requires us to show a Powered by Google logo
next to the autocomplete service predictions. It is rendered to the
bottom of the container.
*/
.poweredByGoogle {
    position: absolute;
    bottom: 30px;
    width: 100%;
    height: 18px;
    background-image: url(./images/powered_by_google_on_non_white_hdpi.png);
    background-size: auto 18px;
    background-position: center;

    @media (--viewportMedium) {
        background-position: center left
            var(--LocationAutocompleteInput_sidePaddingDesktop);
    }
}

/* List of predictions, with a responsive padding size */
.predictions {
    @apply --marketplaceBodyFontStyles;

    margin: 0;
    padding: 14px 0;

    & li {
        color: var(--matterColorLight);
        transition: var(--transitionStyleButton);
        margin: 0; /* Remove the double white space */
        line-height: 24px;

        /* Assign enough vertical padding to make the element at least 44px high */
        padding: 9px 24px;

        @media (--viewportMedium) {
            color: var(--matterColorLightTransparent);
            padding: 10px var(--LocationAutocompleteInput_sidePaddingDesktop);
            margin: 0;
        }

        &:hover,
        &.highlighted {
            cursor: pointer;
            border-left: 6px solid var(--matterColorLight);
            border-top-right-radius: 20px;
            border-bottom-right-radius: 20px;
            color: var(--matterColorLight);
        }
    }
}

.currentLocation {
    @apply --marketplaceH4FontStyles;
}

.currentLocationIcon {
    margin-right: 10px;
}

@import '../../styles/propertySets.css';

.root {
    @apply --marketplaceModalFormRootStyles;
    margin: 0;

    justify-content: flex-start;

    @media (--viewportMedium) {
        justify-content: space-between;
        margin: 0;
    }
}

.signupTitle {
    font-size: 32px;
    font-weight: 500;
    margin: 0 0 24px;
}

.avatarNote {
    font-size: 14px;
    margin: 0 0 24px;
}

.avatarNoteError {
    font-size: 14px;
    color: var(--failColor);
    margin: 0 0 24px;
}

.steps {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
}

.step {
    font-size: 16px;
    font-weight: 500;
}

.double {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    gap: 0;

    @media (--viewportMedium) {
        flex-direction: row;
        gap: 24px;
    }
}

.field {
    margin-bottom: 24px;
}

.fieldIcon {
    margin: 12px 0 0 12px;
}

.input {
    background: #F2F2F2;
    border: none;
    border-radius: 9px;

    padding: 8px 16px 8px 40px;
    margin: 12px 0 0;
}

.inputNoIcon {
    background: #F2F2F2;
    border: none;
    border-radius: 9px;

    padding: 8px 16px 8px 16px;
    margin: 12px 0 0;
}

.phonefield {
    padding: 8px 16px 8px 56px;
}

.textarea {
    background: #F2F2F2;
    border: none;
    border-radius: 9px;

    padding: 8px 16px 8px 16px;
    margin: 12px 0 0;
    width: calc(100% - 32px);
}

.firstNameRoot {
    width: calc(50%);
}

.lastNameRoot {
    width: calc(50%);
}

.password {
    @apply --marketplaceModalPasswordMargins;
}

.phoneField {
    width: 100%;

    @media (--viewportMedium) {
        width: 67%;
    }
}

.phoneNumberNote {
    font: 14px/20px var(--fontFamily);
    margin: 0 0 24px;
}

.zipCodeInput {
    width: 100%;

    @media (--viewportMedium) {
        width: 33%;
    }
}

.userTypeField {
    display: flex;
    flex-direction: column;

    &>legend {
        font-weight: var(--fontWeightSemiBold);
        font-size: 16px;
        margin: 0 0 24px;
    }
}

.userTypeInputWrapper {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    gap: 24px;

    padding: 0;
    margin: 0 0 24px;

    border: none;
}

.check {
    position: absolute;
    opacity: 0;
    width: 0;
    height: 0;
}

.userTypeText {
    background: #F2F2F2;
    border: none;
    border-radius: 9px;
    cursor: pointer;

    text-align: center;
    user-select: none;

    padding: 8px 16px 8px 16px;
    margin: 12px 0 0;
}

.userTypeTextActive {
    background: #059669;
    color: #FFFFFF;
}

.bottomWrapper {
    margin: 0 0 24px;
}

.button {
    background: #598074;
    border-radius: 9px;
    padding: 0 16px;
    margin: 0 0 24px;
    justify-content: center;
    align-items: center;
    gap: 8px;
    min-height: 48px;
}

.loginText {
    color: rgba(18, 18, 18, 0.60);
    font-size: 14px;
    font-weight: 400;

    text-align: center;

    margin: 0;
    width: 100%;
}

.bottomWrapperText {
    @apply --marketplaceModalBottomWrapperText;

    margin: 0;
}

.termsText {
    @apply --marketplaceModalHelperText;
}

.termsLink {
    @apply --marketplaceModalHelperLink;

    &:hover {
        text-decoration: underline;
        cursor: pointer;
    }
}


.uploadAvatarInput {
    display: none;
}

.uploadAvatarWrapper {
    display: flex;
    flex-direction: column;
    margin-top: 0;
    margin-bottom: 24px;
}

.label {
    width: var(--ProfileSettingsForm_avatarSize);
    margin: 0 auto;

    @media (--viewportMedium) {
        width: var(--ProfileSettingsForm_avatarSizeDesktop);
    }
}

.avatarPlaceholder,
.avatarContainer {
    /* Dimension */
    position: relative;
    width: var(--ProfileSettingsForm_avatarSize);
    height: var(--ProfileSettingsForm_avatarSize);

    /* Center content */
    display: flex;
    align-items: center;
    justify-content: center;

    /* Initial coloring */
    background-color: var(--matterColorBright);
    border-radius: calc(var(--ProfileSettingsForm_avatarSize) / 2);
    cursor: pointer;

    @media (--viewportMedium) {
        width: var(--ProfileSettingsForm_avatarSizeDesktop);
        height: var(--ProfileSettingsForm_avatarSizeDesktop);
        border-radius: calc(var(--ProfileSettingsForm_avatarSizeDesktop) / 2);
    }
}

.avatarPlaceholder {
    /* Placeholder border */
    border-style: dashed;
    border-color: var(--matterColorNegative);
    border-width: 2px;

    transition: var(--transitionStyleButton);

    &:hover {
        border-color: var(--matterColorAnti);
    }
}

.avatarPlaceholderTextMobile {
    @media (--viewportMedium) {
        display: none;
    }
}
.avatarPlaceholderText {
    display: none;

    @media (--viewportMedium) {
        display: block;
        max-width: 130px;
        text-align: center;
    }
}

.avatarUploadError {
    /* Placeholder border */
    border-style: dashed;
    border-color: var(--failColor);
    border-width: 2px;
}

.error {
    /* Font */
    @apply --marketplaceH4FontStyles;
    color: var(--failColor);
    margin-top: 18px;
    margin-bottom: 0;

    @media (--viewportMedium) {
        margin-top: 22px;
        margin-bottom: 2px;
    }
}

.avatar {
    width: 100%;
    height: 100%;
}

.changeAvatar {
    /* Font */
    @apply --marketplaceH5FontStyles;

    font-weight: var(--fontWeightMedium);

    /* Positioning: right */
    position: absolute;
    bottom: 27px;
    right: -129px;
    /* Dimensions */
    width: 105px;
    height: 41px;
    padding: 11px 10px 7px 35px;

    /* Look and feel (buttonish) */
    background-color: var(--matterColorLight);
    background-image: url('data:image/svg+xml;utf8,<svg width="14" height="14" viewBox="0 0 14 14" xmlns="http://www.w3.org/2000/svg"><g stroke="%234A4A4A" fill="none" fill-rule="evenodd" stroke-linecap="round" stroke-linejoin="round"><path d="M5.307 11.155L1 13l1.846-4.308L10.54 1 13 3.46zM11 5L9 3M5 11L3 9"/></g></svg>');
    background-repeat: no-repeat;
    background-position: 15px 12px;
    border: solid 1px var(--matterColorNegative);
    border-radius: 2px;

    margin-top: 0;
    margin-bottom: 0;

    @media (--viewportMedium) {
        /* Position: under */
        bottom: -10px;
        right: auto;
        margin-top: 0;
        margin-bottom: 0;
        transition: var(--transitionStyleButton);
        padding: 11px 10px 7px 35px;
    }

    &:hover {
        border: solid 1px var(--matterColorAnti);
    }
}

.uploadingImage {
    /* Dimensions */
    width: var(--ProfileSettingsForm_avatarSize);
    height: var(--ProfileSettingsForm_avatarSize);

    /* Image fitted to container */
    object-fit: cover;
    background-color: var(--matterColorNegative); /* Loading BG color */
    border-radius: calc(var(--ProfileSettingsForm_avatarSize) / 2);
    overflow: hidden;

    display: block;
    position: relative;
    margin: 0;

    @media (--viewportMedium) {
        width: var(--ProfileSettingsForm_avatarSizeDesktop);
        height: var(--ProfileSettingsForm_avatarSizeDesktop);
        border-radius: calc(var(--ProfileSettingsForm_avatarSizeDesktop) / 2);
    }
}

.uploadingImageOverlay {
    /* Cover everything (overlay) */
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;

    /* Overlay style */
    background-color: var(--matterColorLight);
    opacity: 0.8;

    /* Center content */
    display: flex;
    justify-content: center;
    align-items: center;
}

/* Avatar has square aspect ratio */
/* Default is 3:2 */
.squareAspectRatio {
    padding-bottom: 100%;
}

.avatarInvisible {
    visibility: hidden;
    position: absolute;
    top: -1000px;
    left: -1000px;
}
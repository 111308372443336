@import '../../styles/propertySets.css';

.root {
    /* Layout */
    display: flex;
    flex-direction: column;

    /* Remove link's hover effect */
    &:hover {
        text-decoration: none;
    }
}

.root a:hover {
    text-decoration: none;
}

.threeToTwoWrapper {
    /* Layout */
    display: block;
    width: 100%;
    position: relative;
    transition: var(--transitionStyleButton);

    transform: scale(1);

    &:hover {
        transform: scale(1.02);
        box-shadow: var(--boxShadowListingCard);
    }
}

/* Firefox doesn't support image aspect ratio inside flexbox */
.aspectWrapper {
    padding-bottom: 66.6667%; /* 3:2 Aspect Ratio */
    /* background: var(--matterColorNegative);  */
    background: none;
    /* Loading BG color */
}

.rootForImage {
    /* Layout - image will take space defined by aspect ratio wrapper */
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    width: 100%;
    /* border-radius: var(--borderRadius); */
    border-radius: 20px;
}

.info {
    /* Layout */
    display: flex;
    flex-direction: row;
    padding: 16px 0 2px 0;
}

.price {
    /* Layout */
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    flex-shrink: 0;
    margin-right: 22px;
    margin-left: 8px;
}

.priceValue {
    /* Font */
    @apply --marketplaceH3FontStyles;
    color: var(--matterColorAnti);
    font-weight: 600;
    font-size: 24px;

    /* Remove default margins from font */
    margin-top: 0;
    margin-bottom: 0;

    @media (--viewportMedium) {
        margin-top: 0;
        margin-bottom: 0;
    }
}

.perUnit {
    /* Font */
    @apply --marketplaceTinyFontStyles;
    color: var(--matterColor);
    font-size: 13px;
    padding-top: 5px;

    /* Remove default margins from font */
    margin-top: 0;
    margin-bottom: 0;

    @media (--viewportMedium) {
        margin-top: 0;
        margin-bottom: 0;
    }
}

.mainInfoContainer {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    width: 100%;
}

.mainInfo {
    display: flex;
    flex-direction: column;
    justify-content: center;
}

.title {
    /* Font */
    @apply --marketplaceH3FontStyles;
    color: var(--matterColor);

    /* Remove default margins from font */
    margin-top: 0;
    margin-bottom: 0;

    @media (--viewportMedium) {
        margin-top: 0;
        margin-bottom: 0;
    }
}

.certificateInfo {
    /* Font */
    @apply --marketplaceTinyFontStyles;
    color: var(--matterColor);

    /* Remove default margins from font */
    margin-top: 0;
    margin-bottom: 0;

    @media (--viewportMedium) {
        margin-top: 0;
        margin-bottom: 0;
    }
}

.longWord {
    /* fallback option */
    word-break: break-all;
    /* use break-word if available */
    word-break: break-word;
    hyphens: auto;
}

.favIcon {
    padding: 15px;
    position: absolute;
    right: 0;
    cursor: pointer;
}

.instantBookingLabel {
  position: absolute;
  left: 0;
  background-color: #EAEBED;
  border-radius: 4px;
  font-family: var(--fontFamily);
  font-weight: var(--fontWeightRegular);
  font-size: 12px;
  padding: 0 6px;
  margin-top: 15px;
  margin-left: 15px;
}

.infoInnerContainer {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    width: 100%;
}

.ratingContainer {
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding-left: 5px;
    width: 70px;
}

.rating {
    display: flex;
    justify-content: space-between;
    /* align-items: center; */
    width: 50px;
    padding-right: 10px;
    font-size: 23px;
    font-weight: 600;
}

.ratingScore {
    padding-top: 2px;
}

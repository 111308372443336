@import '../../styles/propertySets.css';

.root {
    position: relative;
    padding-top: 24px;
    padding-bottom: 17px;
    /* border-bottom: 1px solid var(--matterColorNegative); */
}

.filterLabel,
.filterLabelSelected {
    @apply --marketplaceH3FontStyles;

    /* Baseline adjustment for label text */
    margin-top: 0;
    margin-bottom: 12px;
    padding: 4px 0 2px 0;
}

.filterLabel {
    color: var(--matterColorDark);
}

.filterLabelSelected {
    color: var(--marketplaceColor);
}

.labelButton {
    /* Override button styles */
    outline: none;
    text-align: left;
    border: none;
    padding: 0;
    cursor: pointer;
}

.clearButton {
    @apply --marketplaceH5FontStyles;
    font-weight: var(--fontWeightMedium);
    color: var(--matterColorAnti);

    /* Layout */
    display: inline;
    float: right;
    margin-top: 6px;
    padding: 0;

    /* Override button styles */
    outline: none;
    text-align: left;
    border: none;

    &:focus,
    &:hover {
        color: var(--matterColor);
    }
}

.plain {
    width: 100%;
    display: none;
}

.isOpen {
    display: block;
}

.root {
    width: 100%;
    position: relative;
}

.sectionTitle {
  font-size: 26px;
  margin-bottom: 1em;
}

.submitButton {
  margin-top: 3em;
}

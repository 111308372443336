.root {
    fill: var(--matterColorNegative);
}

.filled {
    fill: var(--marketplaceColor);
}

.icon {
    padding: 2px;
}

.iconSmall {
    padding: 1px;
    border-radius: 100%;
}

.iconContainer {
    display: flex;
    flex-direction: row;
}

@import '../../styles/propertySets.css';

.root {
    flex-grow: 1;
    width: 100%;
    height: auto;
    display: flex;
    flex-direction: column;
    padding: 11px 24px 0 24px;
}

.form {
    flex-grow: 1;
}

.title {
    margin-bottom: 19px;

    @media (--viewportLarge) {
        margin-bottom: 38px;
        padding: 1px 0 7px 0;
    }
}

.sumary {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
}

.sumaryItem {
    display: flex;
    flex-direction: row;
    flex: 1 1 calc(50% - 10px);
    margin: 5px;
    gap: 16px;
    /* other styles */
}

.completed {
    color: green;
    stroke: green;
    background-color: var(--successColorAlpha);
    border-radius: 50%;
    padding: 5px;
    display: inline-flex;
    justify-content: center;
    align-items: center;
}

.incompleted {
    color: grey;
    stroke: grey;
    background-color: var(--matterColorAlpha);
    border-radius: 10px;
    padding: 5px;
    display: inline-flex;
    justify-content: center;
    align-items: center;
}

.submitButtonText {
    width: 100%;

    @media (--viewportLarge) {
        padding: 0 24px;
        width: fit-content;
    }
}

.actions {
    display: flex;
    flex-direction: column;
    margin: 32px 0;

    @media (--viewportLarge) {
        flex-direction: row;
        gap: 24px;
    }
}

.editListingLink {
    @apply --marketplaceButtonStyles;

    @media (--viewportLarge) {
        width: fit-content;
        padding: 20px 24px;
    }
}
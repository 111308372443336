@import '../../styles/propertySets.css';

.root {
    @apply --marketplaceModalFormRootStyles;

    justify-content: flex-start;
    margin: 0;

    @media (--viewportMedium) {
        justify-content: space-between;
        margin: 0;
    }
}

.signupTitle {
    font-size: 32px;
    font-weight: 500;
    margin: 0 0 24px;
}

.field {
    margin-bottom: 24px;
}

.input {
    background: #F2F2F2;
    border: none;
    border-radius: 9px;

    padding: 8px 16px 8px 40px;
    margin: 12px 0 0;
}

.password {
    @apply --marketplaceModalPasswordMargins;
}

.bottomWrapper {
    margin: 0 0 24px;
}

.button {
    background: #598074;
    border-radius: 9px;
    padding: 0 16px;
    margin: 0 0 24px;
    justify-content: center;
    align-items: center;
    gap: 8px;
    min-height: 48px;
}

/* Adjust the bottomWrapper's margin to match modals without the helper text */
.bottomWrapperText {
    @apply --marketplaceModalBottomWrapperText;

    margin: 0 0 24px;
}

.loginText {
    color: rgba(18, 18, 18, 0.60);
    font-size: 14px;
    font-weight: 400;

    text-align: center;

    margin: 0;
    width: 100%;
}

.recoveryLink {
    @apply --marketplaceModalHelperLink;
}

.recoveryLinkInfo {
    @apply --marketplaceModalHelperText;
}

@import '../../styles/propertySets.css';

.root {
    position: relative;
    box-shadow: var(--boxShadowBottomForm);

    @media (--viewportLarge) {
        box-shadow: none;

        /* Clearfix */
        width: 100%;
        overflow: hidden;
    }
}

.textarea {
    @apply --marketplaceH4FontStyles;
    border-bottom-width: 0;

    /* Avoid text going behind the submit button */
    padding: 22px 52px 25px 0;

    margin: 0;

    /* We need to remove horizontal paddings,
   since textarea uses content-box (autosize library fix). */
    width: calc(100% - 52px);

    border-bottom-width: 2px;
    border-bottom-color: var(--attentionColor);
    background-color: transparent;

    @media (--viewportMedium) {
        margin: 0;
    }

    @media (--viewportLarge) {
        padding: 0 0 5px 0;
        margin: 0;
        width: 100%;
    }
}

.spinner {
    stroke: var(--matterColorLight);
    width: 18px;
    height: 18px;
    stroke-width: 4px;
}

.fillSuccess {
    fill: var(--successColor);
}

.strokeMatter {
    stroke: var(--matterColorNegative);
}

.submitContainer {
    display: flex;
    flex-direction: row;
}

.errorContainer {
    display: block;
    flex: 1;
    text-align: right;
    padding: 17px 24px 0 0;
}

.error {
    @apply --marketplaceH5FontStyles;
    font-weight: var(--fontWeightMedium);
    color: var(--failColor);
    margin: 0;
}

.submitButton {
    @apply --marketplaceButtonStylesMessages;
    @apply --marketplaceH5FontStyles;
    font-weight: var(--fontWeightMedium);

    float: right;
    padding: 0 16px;
    min-height: auto;
    min-width: 150px;
    height: 41px;

    color: var(--matterColorNegative);

    display: inline-block;
    margin: 17px 0 0 0;
    width: auto;

    background-color: var(--matterColorBlue);
    border: 1px solid var(--matterColorBlue);
    border-radius: 20px;
}

.submitButton:hover {
    background-color: var(--matterColorBlue);
    border: 1px solid var(--matterColorBlue);
}

.sendIcon {
    margin: -3px 5px 0 0;
}

.cancelBooking {
    float: left;
    color: #dd3535;
    font-weight: 400;
}

/* Title of the modal */
.modalTitle {
    @apply --marketplaceModalTitleStyles;
}

/* Paragraph for the Modal */
.modalMessage {
    @apply --marketplaceModalParagraphStyles;
}

.buttonsContainer {
    display: flex;
    justify-content: flex-end;
    align-items: center;
}

.theCancelButton {
    border: none;
    background: #dd3535;
    border-radius: 4px;
    height: 65px;
    width: 250px;
    font-size: 18px;
    line-height: 27px;
    text-align: center;
    letter-spacing: -0.3px;
    color: #ffffff;
    margin-left: 2rem;
    font-weight: 400;
    cursor: pointer;
}

.theCancelButton:hover {
    border: 1px solid #dd3535;
    background: #ffffff;
    color: #dd3535;
}

.reconsider {
    color: #4a4a4a;
    font-size: 18px;
    font-weight: 400;
    cursor: pointer;
}

.noEmailPhoneNoWebsiteMessage {
    color: var(--marketplaceColor);
    font-size: 14px;
    line-height: 24px;
}

.root {
    /* Expand to available space */
    flex-grow: 1;

    /* Dimensions */
    width: 100%;
    height: auto;

    /* Layout */
    display: flex;
    flex-direction: column;

    @media (--viewportMedium) {
        padding-top: 1px;
    }
}

.features {
    margin-bottom: 24px;

    @media (--viewportMedium) {
        margin-bottom: 32px;
    }
}

.error {
    color: var(--failColor);
}

.submitButton {
    margin-top: 32px;
    margin-bottom: 24px;
    flex-shrink: 0;

    @media (--viewportLarge) {
        display: inline-block;
        width: 241px;
        margin-top: 32px;
    }
}

@import '../../styles/propertySets.css';

/* ModalInMobile is visible by default */
.root {
    width: 100%;
}

/* Content is hidden in Mobile layout */
.modalHidden {
    display: none;
}

.modalContainer {
  @apply --marketplaceModalInMobileBaseStyles;
  padding: 0;

    @media (--viewportMedium) {
        flex-basis: 576px;
    }
}

.modalContent {
  /* width: 100%; */
  
  margin: 0;
  border-radius: 40px;
  padding: 0;

    @media (--viewportMedium) {
        height: 100%;

        /* ModalInMobile content should work with box-shadows etc. */
        overflow: visible;
    }
}

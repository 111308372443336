@import '../../styles/propertySets.css';

.root {
    display: flex;
    flex-direction: row;
    overflow-x: auto;
}

.tab {
    margin-left: 16px;

    &:first-child {
        margin-left: 0;
    }

    @media (--viewportLarge) {
        margin-left: 24px;
    }
}

.link {
    display: inline-block;
    white-space: nowrap;

    /* Font */
    @apply --marketplaceTabNavFontStyles;

    color: var(--matterColor);
    padding-bottom: 10px;
    padding-top: 10px;

    /* push tabs against bottom of tab bar */
    margin-top: auto;

    border-bottom-width: 2px;
    border-bottom-style: solid;
    border-bottom-color: transparent;

    transition: var(--transitionStyleButton);
    background-image: url("data:image/svg+xml;utf8,<svg width='4' height='32' viewBox='0 0 4 32' xmlns='http://www.w3.org/2000/svg'><path d='M0 0h4v32H0z' fill='var(--marketplaceColorEncoded)' fill-rule='evenodd'/></svg>");
    background-position: calc(var(--TabNav_linkWidth) + 4px) center;
    /* SelectedLink's width (200px) + border thickness (4px) = 204px */

    &:hover {
        text-decoration: none;
        color: var(--matterColorDark);
    }

    @media (--viewportLarge) {
        /* width: var(--TabNav_linkWidth); */
        width: 160px;
        margin-right: 50px;
        border-bottom-width: 0px;
        margin-top: 0;
        margin-bottom: 16px;

        padding-top: 6px;
        /* Drop the text to adjust it to correct baseline */
        padding-bottom: 2px;

        /* &:hover {
      border-bottom: 4px solid black;
      width: 160px;
      background-image: url("data:image/svg+xml;utf8,<svg width='4' height='32' viewBox='0 0 4 32' xmlns='http://www.w3.org/2000/svg'><path d='M0 0h4v32H0z' fill='var(--marketplaceColorEncoded)' fill-rule='evenodd'/></svg>");
      background-position: right center;
    } */
    }
}

.selectedLink {
    border-bottom-color: var(--marketplaceColor);
    color: var(--marketplaceColor);

    @media (--viewportLarge) {
        border-bottom: 4px solid var(--marketplaceColor);
        width: 160px;
        margin-right: 50px;
        /* width: var(--TabNav_linkWidth);
    background-image: url("data:image/svg+xml;utf8,<svg width='4' height='32' viewBox='0 0 4 32' xmlns='http://www.w3.org/2000/svg'><path d='M0 0h4v32H0z' fill='%23000' fill-rule='evenodd'/></svg>");
    background-position: right bottom; */

        &:hover {
            border-bottom: 4px solid var(--marketplaceColor);
            width: 160px;
            color: var(--marketplaceColor);
            /* background-image: url("data:image/svg+xml;utf8,<svg width='4' height='32' viewBox='0 0 4 32' xmlns='http://www.w3.org/2000/svg'><path d='M0 0h4v32H0z' fill='%23000' fill-rule='evenodd'/></svg>");
      background-position: right center; */
        }
    }
}

.disabled {
    pointer-events: none;
    color: rgb(189, 189, 189);
    text-decoration: none;
}

.underline {
    position: absolute;
    left: 0%;
    right: 0%;
    top: 83.33%;
    bottom: 10.42%;
    background: #000000;
}

.divLink {
  cursor: pointer;
}

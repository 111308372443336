@import '../../styles/customMediaQueries.css';
@import '../../styles/propertySets.css';

.root {
}

.pageRoot {
    display: flex;
    padding-bottom: 90px;

    @media (--viewportLarge) {
        padding-bottom: 0;
    }
}

.loadingText {
    margin: 24px;
}

.errorText {
    color: var(--failColor);
    margin: 24px;
}

.sectionImages {
    padding: 10px;
    max-width: 1128px;
    display: flex;
    flex-direction: row;
    justify-content: center;
    margin-top: 10px;
}

.threeToTwoWrapper {
    /* Layout */
    display: block;
    width: 100%;
    position: relative;
}

/* Firefox doesn't support image aspect ratio inside flexbox */
.aspectWrapper {
    position: relative;
    /* allow positioning own listing action bar */
    padding-bottom: 66.6667%;
    /* 3:2 Aspect Ratio */
    /* background-color: var(--matterColorNegative); */
    background-color: none;
    /* Loading BG color */

    /* Image carousel can be opened from the image, therefore we should show a pointer */
    cursor: pointer;

    @media (--viewportMedium) {
        padding-bottom: 0;
        /* No fixed aspect on desktop layouts */
    }

    @media (max-width: 768px) {
        padding-bottom: 0;
    }
}

.actionBar {
    /* position: absolute; */
    display: flex;
    justify-content: space-between;
    /* top: 13px;
  left: 13px; */
    /* width: calc(100% - 36px); */
    width: 100%;
    color: var(--matterColorNegative);
    background-color: var(--matterColor);
    z-index: 1;
    /* bring on top of mobile image */

    /* Action bar prevents the image click events going to the parent and
   should not show a pointer */
    cursor: initial;

    border-radius: var(--borderRadius);
}

.ownListingText {
    @apply --marketplaceH4FontStyles;
    margin: 14px 12px 11px 24px;

    @media (--viewportMedium) {
        margin: 25px 12px 22px 24px;
    }
}

.ownListingTextPendingApproval {
    color: var(--attentionColor);
}

.closedListingText {
    @apply --marketplaceH4FontStyles;
    margin: 14px 12px 11px 24px;
    text-align: center;
    width: 100%;

    @media (--viewportMedium) {
        margin: 25px 12px 22px 24px;
    }
}

.editListingLink {
    @apply --marketplaceH4FontStyles;
    flex-shrink: 0;
    margin: 0;
    padding: 14px 24px 11px 12px;
    color: var(--matterColorNegative);

    transition: var(--transitionStyleButton);

    &:hover {
        color: var(--matterColorLight);
        text-decoration: none;
    }

    @media (--viewportMedium) {
        margin: 0;
        padding: 25px 24px 22px 12px;
    }
}

.editIcon {
    margin: -6px 7px 0 0;
}

.rootForImage {
    /* Layout - image will take space defined by aspect ratio wrapper */
    position: relative;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    width: 100%;

    transition: var(--transitionStyleButton);

    @media (--viewportMedium) {
        position: relative;
        top: auto;
        bottom: auto;
        left: auto;
        right: auto;
        height: calc(0.41 * 100vw);
        max-height: 50vh;
        object-fit: cover;
        border-radius: var(--borderRadiusAlt);

        &:hover {
            transform: scale(1.005);
            box-shadow: var(--boxShadowListingCard);
        }
    }
}

.imageContainer {
    display: flex;
    flex-direction: row;
    position: relative;
    width: 100%;

    /* border-radius: var(--borderRadiusAlt); */
    /* border: 2px solid black; */
    max-height: 50vh;

    @media (max-width: 768px) {
        width: 100%;
        height: calc(0.41 * 100vw);
        position: relative;
    }
}

.imageColumnFirst {
    position: relative;
    width: 60%;
    height: auto;

    @media (max-width: 768px) {
        width: 100%;
    }
}

.imageColumnSecond {
    position: relative;
    width: 40%;
    height: auto;

    @media (max-width: 768px) {
        width: 0%;
    }
}

.imageFirst {
    object-fit: cover;
    height: 100%;
    width: 100%;
    border-radius: 40px 0px 0px 40px;

    &:hover {
        filter: brightness(80%);
    }

    @media (max-width: 768px) {
        border-radius: 40px;
    }
}

/* 2 Image */
.imageSecondAlt {
    padding-left: 10px;
    padding-bottom: 0px;
    height: 100%;
    width: 100%;
    object-fit: cover;
    border-radius: 0px 40px 40px 0px;

    &:hover {
        filter: brightness(80%);
    }

    @media (max-width: 768px) {
        display: none;
        width: 0%;
    }
}

/* 3 Image */
.imageSecond {
    padding-left: 10px;
    padding-bottom: 5px;
    height: 50%;
    width: 100%;
    object-fit: cover;
    border-radius: 0px 40px 0px 0px;

    &:hover {
        filter: brightness(80%);
    }

    @media (max-width: 768px) {
        display: none;
        width: 0%;
    }
}

.imageThird {
    padding-left: 10px;
    padding-top: 5px;
    height: 50%;
    width: 100%;
    object-fit: cover;
    border-radius: 0px 0px 40px 0px;

    &:hover {
        filter: brightness(80%);
    }

    @media (max-width: 768px) {
        display: none;
        width: 0%;
    }
}

/* END 3 Image */

.viewPhotos {
    @apply --marketplaceTinyFontStyles;
    font-weight: var(--fontWeightMedium);

    /* Position and dimensions */
    position: absolute;
    bottom: 19px;
    right: 24px;
    margin: 0;
    padding: 8px 13px 6px 13px;

    /* Colors */
    background-color: var(--matterColorLight);

    /* Borders */
    border: none;
    border-radius: var(--borderRadius);

    cursor: pointer;
    transition: var(--transitionStyleButton);

    &:hover {
        background-color: var(--marketplaceColor);
        color: var(--matterColorLight);
    }

    @media (--viewportMedium) {
        margin: 0;
    }
}

.carouselModalScrollLayer {
    width: 100vw;
    height: 100vh;
    background-color: rgba(1, 1, 1, 0.92);
}

.carouselModalContainer {
    width: 100%;
    height: 100%;
}

.contentContainer {
    @media (--viewportMedium) {
        max-width: 1050px;
        /* margin: 0 10px; */
        /* padding: 0 24px; */
        display: flex;
        position: relative;
    }

    @media (--viewportLarge) {
        max-width: 1110px;
        /* padding: 0 36px; */
        margin: 0 auto 117px;
    }

    @media (--viewportXLarge) {
        max-width: 1056px;
        padding: 0;
    }
}

.mainContent {
    flex-basis: 100%;
    margin-bottom: 23px;

    @media (--viewportMedium) {
        margin-top: 50px;
        margin-bottom: 51px;
        flex-shrink: 0;
    }

    @media (--viewportLarge) {
        margin-top: 50px;
        margin-bottom: 0px;
        /* contentContainer's full width - (bookingColumn + two paddings + margin + border) */
        max-width: calc(100% - 433px);
        flex-basis: calc(100% - 433px);
        flex-shrink: 0;
        flex-grow: 1;
    }
}

.bookingPanel {
    @media (--viewportLarge) {
        display: block;
        /* margin-top: 79px;
    margin-left: 40px; */
        /* margin: 79px 40px 40px 20px;
    border: 1px solid #DDDDDD;
    box-shadow: 0px 6px 16px rgba(0, 0, 0, 0.12);
    border-radius: 40px;
    padding: 40px; */

        /* The calendar needs 312px */
        flex-basis: 312px;
        flex-shrink: 0;
    }
}

.courtInfoContainer {
    display: flex;
    flex-direction: row;
}

.sectionAvatar {
    /* Position (over the listing image)*/
    margin-left: 24px;
    /* margin-top: -72px; */
    margin-right: 0px;

    /* Rendering context to the same lavel as listing image */
    position: relative;

    /* Flex would give too much width by default. */
    width: 180px;

    /* margin-right: 8px; */
    float: left;
    margin-bottom: 32px;

    @media (--viewportMedium) {
        /* position: absolute; */
        top: 30px;
        margin-left: 0;
        margin-top: 0;
        float: none;
    }
}

.avatarMobile {
    display: flex;
    width: 96px;
    height: 96px;

    @media (--viewportMedium) {
        display: none;
        /* Hide the medium avatar from the bigger screens */
    }
}

.avatarDesktop {
    display: none;
    /* Hide the large avatar from the smaller screens */
    width: 96px;
    height: 96px;

    @media (--viewportMedium) {
        display: flex;
    }
}

.authorName {
    font-family: Poppins;
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
}

.courtListingIntro {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    @media (--viewportMedium) {
        flex-direction: row;
    }
}

.ratingfav {
    display: flex;
    flex-direction: row;
    padding-top: 25px;
}

.ratingContainer {
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 2px 15px 2px 0px;

    font-family: Poppins;
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
}

.favContainer {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    padding: 6px 15px 5px 5px;

    font-family: Poppins;
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
}

.favIcon {
    width: 20px;
}

.favIconHover {
    cursor: pointer;
}

.initialsDesktop {
    font-size: 48px;
    font-weight: var(--fontWeightRegular);
    /* padding-bottom: 8px; */
}

.sectionHeading {
    margin-top: 12px;
    margin-bottom: 14px;

    @media (--viewportMedium) {
        display: flex;
        margin-bottom: 31px;
    }
}

.desktopPriceContainer {
    display: none;

    @media (--viewportLarge) {
        /* Layout */
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        flex-shrink: 0;
        margin: 0 48px 0 0;
        padding: 0;
    }
}

.desktopPriceValue {
    /* Font */
    @apply --marketplaceModalTitleStyles;
    color: var(--marketplaceColor);

    @media (--viewportMedium) {
        margin-top: 0;
        margin-bottom: 1px;
    }
}

.desktopPerUnit {
    /* Font */
    @apply --marketplaceH5FontStyles;
    color: var(--matterColor);

    @media (--viewportMedium) {
        margin-top: 0;
        margin-bottom: 0;
    }
}

.heading {
    margin-bottom: 23px;
    padding: 0 24px;

    @media (--viewportMedium) {
        margin-bottom: 0;
        padding: 0;
        margin-bottom: 33px;
    }
}

.address {
    display: flex;
    flex-wrap: row;
}

.lightFont {
    font-weight: 300;
}

.title {
    /* Font */
    @apply --marketplaceModalTitleStyles;
    color: var(--matterColor);

    /* Layout */
    width: 100%;
    margin-top: 0;
    margin-bottom: 0;

    @media (--viewportMedium) {
        margin-top: 0;
        margin-bottom: 2px;
    }
}

.author {
    width: 100%;
    @apply --marketplaceH5FontStyles;
    margin-top: 7px;
    margin-bottom: 0;

    @media (--viewportMedium) {
        margin-top: 0;
        margin-bottom: 0;
        color: var(--matterColor);
    }
}

.authorNameLink {
    color: var(--matterColor);
}

.contactWrapper {
    display: none;

    @media (--viewportMedium) {
        display: inline;
    }
}

.separator {
    margin: 0 6px;
}

.contactLink {
    @apply --marketplaceLinkStyles;
    @apply --marketplaceH5FontStyles;
    margin: 0;
}

.sectionDescription {
    padding: 0 24px;
    margin-bottom: 35px;

    @media (--viewportMedium) {
        padding: 0;
        margin-bottom: 36px;
    }
}

.descriptionTitle {
    /* Font */
    @apply --marketplaceH3FontStyles;
    color: var(--matterColorAnti);

    margin-top: 0;
    margin-bottom: 13px;

    font-family: Poppins;
    font-style: normal;
    font-weight: 500;
    font-size: 24px;

    @media (--viewportMedium) {
        margin-top: 0;
        margin-bottom: 12px;
    }
}

/* .centerTitle {
  display: flex;
  flex-direction: row;
} */

.youtubeContainer {
    display: flex;
    /* justify-content: space-around; */
}

.sectionInfo {
    padding: 0 24px;
    margin-bottom: 35px;

    @media (--viewportMedium) {
        padding: 0;
        margin-bottom: 36px;
    }
}

.sectionInfoList {
    display: flex;
    margin-bottom: 10px;
}

.sectionInfoListCol {
    margin-bottom: 10px;
    display: flex;
    flex-direction: column;
}

.infoTitle {
    font-family: Poppins;
    font-style: normal;
    font-weight: 600;
    font-size: 18px;
    color: #484848;
}

.infoText {
    font-family: Poppins;
    font-style: normal;
    font-weight: normal;
    font-size: 17px;
}

.sectionCourtInfo {
    padding: 0 24px;
    margin-bottom: 35px;
    width: 300px;

    @media (--viewportMedium) {
        padding: 0;
        margin-bottom: 36px;
    }
}

.courtInfoTitle {
    display: flex;
    align-items: center;

    /* Font */
    @apply --marketplaceH3FontStyles;
    color: var(--matterColorAnti);

    margin-top: 0;
    margin-bottom: 15px;

    font-family: Poppins;
    font-style: normal;
    font-weight: 500;
    font-size: 24px;

    @media (--viewportMedium) {
        margin-top: 0;
        margin-bottom: 12px;
    }
}

.labelContainer {
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 4px;

    background-color: #eaebed;
    border-radius: 4px;

    font-size: 10px;
    height: 24px;
    width: fit-content;
}

.sectionPrecaution {
    padding: 0 24px;
    margin-bottom: 35px;

    @media (--viewportMedium) {
        padding: 0;
        margin-bottom: 36px;
    }
}

.precautionTitle {
    /* Font */
    @apply --marketplaceH3FontStyles;
    color: var(--matterColorAnti);

    margin-top: 0;
    margin-bottom: 13px;

    font-family: Poppins;
    font-style: normal;
    font-weight: 500;
    font-size: 24px;

    @media (--viewportMedium) {
        margin-top: 0;
        margin-bottom: 12px;
    }
}

.precautionBanner {
    display: flex;
    flex-direction: row;
    left: 0px;
    top: 0px;

    background: linear-gradient(
        90deg,
        #fef3c7 0%,
        rgba(254, 243, 199, 0.5) 100%
    );
    width: 100%;
    border-radius: 20px;
    @media (--viewportMedium) {
        width: 646px;
    }
}

.precautionVirus {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
}

.virusImage {
    width: 120px;
    height: 105px;
    border-radius: 20px 0px 0px 0px;
}

.precautionInnerContainer {
    display: flex;
    flex-direction: column;
    padding: 16px 16px 16px 6px;
    @media (--viewportMedium) {
        flex-direction: row;
    }
}

.precautionContent {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    /* width: 50px; */
}

.contentHeader {
    font-family: Poppins;
    font-style: normal;
    font-weight: 600;
}

.contentBody {
    font-family: Poppins;
    font-style: normal;
    font-weight: 400;
}

.precautionButtonContainer {
    display: flex;
    flex-direction: column-reverse;
    width: 120px;
    margin-top: 8px;
    @media (--viewportMedium) {
        margin-top: 0px;
    }
}

.precautionButton {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;

    width: 100px;
    height: 35px;

    background: var(--matterColorAnti);
    color: #eee;
    border-radius: 20px;
    border-color: transparent;
    cursor: pointer;

    &:hover {
        text-decoration: none;
    }
}

.sectionFeatures {
    padding: 0 24px;
    margin-bottom: 32px;

    @media (--viewportMedium) {
        padding: 0;
        margin-bottom: 52px;
    }
}

.featuresTitle {
    /* Font */
    @apply --marketplaceH3FontStyles;
    color: var(--matterColorAnti);

    margin-top: 0;
    margin-bottom: 16px;

    font-family: Poppins;
    font-style: normal;
    font-weight: 500;
    font-size: 24px;

    @media (--viewportMedium) {
        margin-top: 0;
        margin-bottom: 12px;
    }
}

.description {
    margin-top: 0;
    margin-bottom: 0;

    /* Preserve newlines, but collapse other whitespace */
    white-space: pre-line;

    font-family: Poppins;
    font-style: normal;
    font-weight: normal;
    font-size: 17px;

    @media (--viewportMedium) {
        margin-top: 0;
        margin-bottom: 0;
    }
}

.sectionMap {
    padding: 0 24px;
    margin-bottom: 35px;

    @media (--viewportMedium) {
        padding: 0;
        margin-bottom: 0;
    }
}

.locationTitle {
    /* Font */
    @apply --marketplaceH3FontStyles;
    color: var(--matterColorAnti);

    margin-top: 0;
    margin-bottom: 20px;

    font-family: Poppins;
    font-style: normal;
    font-weight: 500;
    font-size: 24px;

    @media (--viewportMedium) {
        margin-top: 0;
        margin-bottom: 28px;
    }
}

.mapDetailsNote {
    font-family: Poppins;
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
}

.sectionReviews,
.SectionNearbyListings {
    padding: 0 24px;
    margin-bottom: 5px;

    @media (--viewportMedium) {
        padding: 0;
    }

    @media (--viewportLarge) {
        margin-bottom: 3px;
    }
}

.reviewsHeading {
    @apply --marketplaceH3FontStyles;
    color: var(--matterColorAnti);

    margin: 52px 0 20px 0;

    font-family: Poppins;
    font-style: normal;
    font-weight: 500;
    font-size: 24px;

    @media (--viewportMedium) {
        margin: 53px 0 27px 0;
    }

    @media (--viewportLarge) {
        margin: 50px 0 26px 0;
    }
}

.sectionHost {
    position: relative;
    padding: 0 24px;
    margin-bottom: 5px;

    @media (--viewportMedium) {
        padding: 0;
    }

    @media (--viewportLarge) {
        margin-bottom: 3px;
    }
}

.yourHostHeading {
    @apply --marketplaceH3FontStyles;
    color: var(--matterColorAnti);

    margin: 54px 0 23px 0;

    @media (--viewportMedium) {
        margin: 53px 0 27px 0;
    }

    @media (--viewportLarge) {
        margin: 53px 0 27px 0;
    }
}

.enquiryModalContent {
    flex-grow: 1;
    display: flex;
}

.enquiryForm {
    flex-grow: 1;
    margin: 70px 0 100px 0;

    @media (--viewportMedium) {
        display: flex;
        flex-direction: column;
        min-height: 400px;
        margin: 0;
    }
}

.enquirySubmitButtonWrapper {
    position: fixed;
    bottom: 0;
    left: 0;
    right: 0;
    padding: 24px;
    background-color: var(--matterColorLight);

    @media (--viewportMedium) {
        position: static;
        padding: 0;
        margin-top: auto;
    }
}

.map {
    /* Dimensions: Map takes all available space from viewport (excludes action button and section title) */
    height: calc(100vh - 193px);
    width: 100%;

    /* Static map: dimensions are 640px */
    max-width: 640px;
    max-height: 640px;
    background-color: #eee;
    padding: 0;
    border: 0;
    cursor: pointer;

    @media (--viewportMedium) {
        height: 75vh;
    }

    @media (--viewportLarge) {
        height: 417px;
    }
}

.sectionPolicy {
    padding: 0 24px;
    margin-bottom: 35px;
    margin-top: 30px;
    margin-right: 35px;

    @media (--viewportMedium) {
        padding: 0;
        margin-bottom: 36px;
    }
}

.policyTitle {
    /* Font */
    @apply --marketplaceH3FontStyles;
    color: var(--matterColorAnti);

    margin-top: 0;
    margin-bottom: 13px;

    font-family: Poppins;
    font-style: normal;
    font-weight: 500;
    font-size: 24px;

    @media (--viewportMedium) {
        margin-top: 0;
        margin-bottom: 12px;
    }
}

.policyContent {
    margin-top: 0;
    margin-bottom: 0;
    margin-right: 35px;

    /* Preserve newlines, but collapse other whitespace */
    white-space: pre-line;

    font-family: Poppins;
    font-style: normal;
    font-weight: normal;
    font-size: 17px;

    @media (--viewportMedium) {
        margin-top: 0;
        margin-bottom: 0;
    }
}

.longWord {
    /* fallback option */
    word-break: break-all;
    /* use break-word if available */
    word-break: break-word;
    hyphens: auto;
}

.listingCard {
    margin-bottom: 36px;
    width: 100%;

    @media (--viewportMedium) {
        width: calc(50% - 25px);
        margin-right: 24px;
    }
    @media (--viewportLarge) {
        width: calc(50% - 25px);
        margin-right: 24px;
    }
}

@import '../../styles/propertySets.css';

.root {
    @apply --marketplaceModalFormRootStyles;
    margin: 0;

    justify-content: flex-start;

    @media (--viewportMedium) {
        justify-content: space-between;
        margin: 0;
    }
}

.signupTitle {
    font-size: 32px;
    font-weight: 500;
    margin: 0 0 24px;
}

.steps {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
}

.step {
    font-size: 16px;
    font-weight: 500;
}

.double {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    gap: 0;

    @media (--viewportMedium) {
        flex-direction: row;
        gap: 24px;
    }
}

.field {
    margin-bottom: 24px;
}

.fieldIcon {
    margin: 12px 0 0 12px;
}

.input {
    background: #F2F2F2;
    border: none;
    border-radius: 9px;

    padding: 8px 16px 8px 40px;
    margin: 12px 0 0;
}

.inputNoIcon {
    background: #F2F2F2;
    border: none;
    border-radius: 9px;

    padding: 8px 16px 8px 16px;
    margin: 12px 0 0;
}

.textarea {
    background: #F2F2F2;
    border: none;
    border-radius: 9px;

    padding: 8px 16px 8px 16px;
    margin: 12px 0 0;
    width: calc(100% - 32px);
}

.firstNameRoot {
    width: calc(50%);
}

.lastNameRoot {
    width: calc(50%);
}

.password {
    @apply --marketplaceModalPasswordMargins;
}

.phoneField {
    width: 100%;

    @media (--viewportMedium) {
        width: 67%;
    }
}

.phoneNumberNote {
    font: 14px/20px var(--fontFamily);
    margin: 0 0 24px;
}

.zipCodeInput {
    width: 100%;

    @media (--viewportMedium) {
        width: 33%;
    }
}

.userTypeField {
    display: flex;
    flex-direction: column;

    &>legend {
        font-weight: var(--fontWeightSemiBold);
        font-size: 16px;
        margin: 0 0 24px;
    }
}

.userTypeInputWrapper {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    gap: 24px;

    padding: 0;
    margin: 0 0 24px;

    border: none;
}

.check {
    position: absolute;
    opacity: 0;
    width: 0;
    height: 0;
}

.userTypeText {
    background: #F2F2F2;
    border: none;
    border-radius: 9px;
    cursor: pointer;

    text-align: center;
    user-select: none;

    padding: 8px 16px 8px 16px;
    margin: 12px 0 0;
}

.userTypeTextActive {
    background: #059669;
    color: #FFFFFF;
}

.bottomWrapper {
    margin: 0 0 24px;
}

.button {
    background: #598074;
    border-radius: 9px;
    padding: 0 16px;
    margin: 0 0 24px;
    justify-content: center;
    align-items: center;
    gap: 8px;
    min-height: 48px;
}

.loginText {
    color: rgba(18, 18, 18, 0.60);
    font-size: 14px;
    font-weight: 400;

    text-align: center;

    margin: 0;
    width: 100%;
}

.bottomWrapperText {
    @apply --marketplaceModalBottomWrapperText;

    margin: 0;
}

.termsText {
    @apply --marketplaceModalHelperText;
}

.termsLink {
    @apply --marketplaceModalHelperLink;

    &:hover {
        text-decoration: underline;
        cursor: pointer;
    }
}
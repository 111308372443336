@import '../../styles/propertySets.css';

.root {
    position: relative;
    display: inline-block;
}

.label {
    @apply --marketplaceButtonStylesSecondary;
    @apply --marketplaceSearchFilterLabelFontStyles;

    padding: var(--marketplaceButtonSmallDesktopPadding);
    width: auto;
    height: auto;
    min-height: 0;
    border-radius: 4px;

    &:focus {
        outline: none;
        background-color: var(--matterColorLight);
        border-color: transparent;
        text-decoration: none;
        box-shadow: var(--boxShadowFilterButton);
    }
}

.labelSelected {
    @apply --marketplaceButtonStyles;
    @apply --marketplaceSearchFilterLabelFontStyles;
    font-weight: var(--fontWeightSemiBold);

    padding: var(--marketplaceButtonSmallDesktopPadding);
    width: auto;
    height: auto;
    min-height: 0;
    border-radius: 4px;
    border: 1px solid var(--marketplaceColor);

    &:hover,
    &:focus {
        border: 1px solid var(--marketplaceColorDark);
    }
}

.fieldGroup {
    margin: 0;
    padding: 0;
    border: none;
}

.fieldGroupPlain {
    margin: 0;
    padding-left: 20px;
    padding-bottom: 30px;
    border: none;
}

.list {
    margin: 0;
}

.item {
    padding: 2px 0;

    /* Fix broken multi-column layout in Chrome */
    page-break-inside: avoid;

    @media (--viewportMedium) {
        padding: 4px 0;
    }
}

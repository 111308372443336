.root {
    position: relative;
}

.input {
    border-bottom-color: var(--attentionColor);
}

.inputSuccess {
    border-bottom-color: var(--successColor);
}

.inputError {
    border-bottom-color: var(--failColor);
}

.textarea {
}

.labelFont {
    font-weight: var(--fontWeightSemiBold);
    font-size: 16px;
    margin-top: 0;
    margin-bottom: 0;
    padding-top: 6px;
    padding-bottom: 2px;
}

.icon {
    position: absolute;
    left: 0;
    top: 0;
    bottom: 0;
    display: flex;
    align-items: center;
    padding-right: 10px;
}
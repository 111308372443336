@import '../../styles/propertySets.css';

.root {
    flex-grow: 1;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    background-color: var(--matterColorBright);
    position: relative;
}

.filtersWrapper {
}

.footerWrapper {
    position: sticky;
    background-color: white;
    bottom: 0;
    width: 100%;
    border-top: 1px solid var(--matterColorNegative);
}

.footer {
    display: flex;
    margin-top: 16px;
    margin-bottom: 16px;
    width: 100%;
}

.resetAllButton {
    @apply --marketplaceLinkStyles;
    @apply --marketplaceH5FontStyles;
    font-weight: var(--fontWeightMedium);
    color: var(--matterColorAnti);
    font-size: 16px;

    /* Layout */
    margin: 0 auto 0 0;

    /* Override button styles */
    outline: none;

    &:focus,
    &:hover {
        /* color: var(--matterColor); */
    }
}

.applyButton {
    @apply --marketplaceLinkStyles;
    @apply --marketplaceH5FontStyles;
    font-weight: var(--fontWeightMedium);
    color: var(--matterColorAnti);
    font-size: 16px;

    /* Layout */
    margin: 0 24px 0 24px;

    /* Override button styles */
    outline: none;

    &:focus,
    &:hover {
        /* color: var(--matterColor); */
    }
}

.cancelButton {
    @apply --marketplaceLinkStyles;
    @apply --marketplaceH5FontStyles;
    font-weight: var(--fontWeightMedium);
    font-size: 16px;

    /* Layout */
    margin: 0;

    /* Override button styles */
    outline: none;

    &:focus,
    &:hover {
        /* color: var(--marketplaceColorDark); */
    }
}

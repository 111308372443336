@import '../../styles/propertySets.css';

.root {
    display: flex;
    flex-direction: column;
}

.bookingDates {
    flex-shrink: 0;
    margin-bottom: 8px;

    /* Ensure that calendar dropdown gets some stacking context relative to other form items below */
    z-index: 1;
}

.priceBreakdownContainer {
    padding: 0 24px;
    margin-top: 10px;
    margin-bottom: 40px;

    @media (--viewportMedium) {
        padding: 0;
        margin-bottom: 0;
        margin-top: 10px;
    }
}

.priceBreakdownTitle {
    /* Font */
    color: var(--matterColorAnti);

    margin-top: 5px;
    margin-bottom: 14px;

    @media (--viewportMedium) {
        margin-top: 5px;
        margin-bottom: 26px;
    }
}

.receipt {
    flex-shrink: 0;
    margin: 0 0 24px 0;
}

.error {
    color: var(--failColor);
    margin: 0 24px;
    display: inline-block;
}

.spinner {
    margin: auto;
}

.sideBarError {
    @apply --marketplaceH4FontStyles;
    color: var(--failColor);
    margin: 0 24px 12px 24px;

    @media (--viewportMedium) {
        margin: 0 0 12px 0;
    }
}

.smallPrint {
    @apply --marketplaceTinyFontStyles;
    /* color: var(--matterColorAnti); */
    text-align: center;
    margin: auto 24px 20px 24px;
    flex-shrink: 0;

    @media (--viewportMedium) {
        margin-top: auto;
        margin-bottom: 20px;
    }

    @media (--viewportLarge) {
        margin-top: 4px;
        margin-bottom: 21px;
    }
}

.submitButtonWrapper {
    flex-shrink: 0;
    padding: 0 24px 24px 24px;
    width: 100%;

    @media (--viewportMedium) {
        padding: 0;
        width: 100%;
    }
}

.dateListContainer {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    /* align-items    : center; */
    /* justify-content: center; */
}

.dateListContainer > .dateListItemTime {
    flex: 1 1 30%;
    /*grow | shrink | basis */
    height: 25px;
}

.dateListItem {
    /* border: 1px solid #f76707; */
    font-weight: bold;
}

.dateListItemTime {
    /* border: 1px solid #f76707; */
    text-align: end;
    padding-right: 45px;
}

.submitButtonClasses {
    margin-top: 0.5rem;
}

.removeButton {
    /* border: 1px solid #f76707; */
    cursor: pointer;
    align-items: flex-end;
    padding-left: 0px;
    color: red;
}

.removeButton:hover {
    /* font-weight: bold; */
    color: darkred;
}

.enquiryButton {
    background-color: var(--marketplaceColorDark) !important;

    &:hover {
        background-color: var(--marketplaceColorDark);
    }
}

.instantBookInfo {
    @apply --marketplaceTinyFontStyles;
    /* color: var(--matterColorAnti); */
    text-align: center;
    margin: auto 8px 20px 8px;
    flex-shrink: 0;

    @media (--viewportMedium) {
        margin-top: auto;
        margin-bottom: 20px;
    }

    @media (--viewportLarge) {
        margin-top: 12px;
        margin-bottom: 21px;
    }
}

.bookingDiscountNote {
    padding-top: 10px;
    font-style: normal;
    font-weight: 500;
    font-size: 13px;
    line-height: 1;
}

.maxDates {
    font-style: normal;
    font-weight: 500;
    font-size: 13px;
    line-height: 1;
    color: red;
}

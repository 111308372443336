@import '../../styles/propertySets.css';

.root {
    /* Font */
    @apply --marketplaceH6FontStyles;
    color: var(--matterColorLight);
    text-align: center;

    background-color: var(--failColor);

    /* Layout of a red dot */
    display: flex;
    align-items: center;
    justify-content: center;
    width: 20px;
    height: 20px;
    border-radius: 10px;
    float: right;

    /* Font margins off */
    margin-top: 0;
    margin-bottom: 0;
}

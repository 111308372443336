@import '../assets/sanitize.css';
@import './customMediaQueries.css';
@import './propertySets.css';

/* ================ Custom Properties aka CSS variables ================ */

/**
 * These variables are available in global scope through ":root"
 * element (<html> tag). You can use Custom Properties to achieve
 * more dynamic theming by changing the value on the fly with JS:
 * document.documentElement.style.setProperty("--marketplaceColor", '#55AA55');
 *
 * Read more about how to use CSS Custom Properties
 * https://developer.mozilla.org/en-US/docs/Web/CSS/Using_CSS_custom_properties
 */

:root {
    /* ================ Colors ================ */

    /* main color #059669; */
    --marketplaceColor: #059669;
    --marketplaceColorLight: #0b6d4f;
    /* --marketplaceColorDark: #006a80; */
    --marketplaceColorDark: #2563eb;

    /* Used with inline CSS SVGs */
    --marketplaceColorEncoded: %230095b3;

    --successColor: #2ecc71;
    --successColorDark: #239954;
    --successColorAlpha: #2ecc7133;
    --successColorLight: #f0fff6;
    --failColor: #ff0000;
    --failColorLight: #fff0f0;
    --attentionColor: #ffaa00;
    --attentionColorAlpha: #ffaa0033;
    --attentionColorLight: #fff7f0;
    --bannedColorLight: var(--marketplaceColorLight);
    --bannedColorDark: var(--marketplaceColor);

    --matterColorDark: #000000;
    --matterColor: #4a4a4a;
    --matterColorAlpha: #4a4a4a33;
    --matterColorAnti: #059669;
    --matterColorNegative: #e7e7e7;
    /* --matterColorNegative: none; */
    --matterColorBright: #fcfcfc;
    --matterColorLight: #ffffff;

    --matterColorBlue: #2563eb;
    --matterColorGreen: #c5e146;
    --matterColorGrey: #bcbcbc;

    --matterColorLightTransparent: rgba(255, 255, 255, 0.65);

    /* ================ Font ================ */
    --fontFamily: 'poppins', Helvetica, Arial, sans-serif;

    --fontWeightRegular: 400;
    --fontWeightMedium: 500;
    --fontWeightSemiBold: 600;
    --fontWeightBold: 700;

    --fontWeightHighlightEmail: var(--fontWeightBold);

    /* ================ Spacing unites ================ */

    /* Multiples of mobile and desktop spacing units should be used with margins and paddings. */
    --spacingUnit: 6px;
    --spacingUnitDesktop: 8px;

    /* Shadows */
    --boxShadow: 0 2px 4px 0 rgba(0, 0, 0, 0.1);
    --boxShadowTop: 0 -2px 4px 0 rgba(0, 0, 0, 0.1);
    --boxShadowLight: 0 2px 4px 0 rgba(0, 0, 0, 0.05);
    --boxShadowPopup: 0 8px 16px 0 rgba(0, 0, 0, 0.3);
    --boxShadowPopupLight: 0 3px 6px 0 rgba(0, 0, 0, 0.2);
    --boxShadowBreakdown: 0 3px 6px 0 rgba(0, 0, 0, 0.2);
    --boxShadowButton: 0 4px 8px 0 rgba(0, 0, 0, 0.1);
    --boxShadowListingCard: 0 0 50px 0 rgba(0, 0, 0, 0.1);
    --boxShadowNotFoundPageSearch: 0 2px 4px 0 rgba(0, 0, 0, 0.5);
    --boxShadowSectionLocationHover: 0 10px 30px 0 rgba(0, 0, 0, 0.1);
    --boxShadowBottomForm: 0 -2px 4px 0 rgba(0, 0, 0, 0.05);
    --boxShadowFilterButton: 0 4px 16px 0 rgba(0, 0, 0, 0.2);

    /* ================ Z-index base levels ================ */

    /* topbar is positioned slightly above content */
    --zIndexTopbar: 10;
    /* small popups on UI should use z-indexes above 50 */
    --zIndexPopup: 50;
    /* modals and UI overlays should use z-indexes above 100 */
    --zIndexModal: 100;
    /* generic error message should overlay even modals */
    --zIndexGenericError: 200;

    /* ================ Border radius ================ */

    --borderRadius: 2px;
    --borderRadiusAlt: 40px;
    --borderRadiusMobileSearch: 3px;

    /* ================ Transition styles ================ */

    --transitionStyle: ease-in 0.2s;
    --transitionStyleButton: ease-in-out 0.1s;

    /* ================ Topbar related ================ */

    --topbarHeight: 60px;
    --topbarHeightDesktop: 72px;

    --TopbarMobileMenu_topMargin: 96px;

    --Topbar_logoHeight: 26px;
    --CheckoutPage_logoHeight: 26px;
    --CheckoutPage_logoHeightDesktop: 27px;

    --TopbarSearchForm_inputHeight: 53px;
    --TopbarSearchForm_topbarMargin: 94px;
    --TopbarSearchForm_bottomBorder: 3px;

    /* ================ Modal default padding ================ */

    --modalPadding: 24px 24px 48px 24px;
    --modalPaddingMedium: 55px 60px 55px 60px;

    /* ================ LocationAutocompleteInput bottom attribution padding ================ */

    /* Google Maps needs 72px bottom padding to accommodate logo, Mapbox doesn't have one */
    --locationAutocompleteBottomPadding: 8px;

    --LocationAutocompleteInput_inputHeight: 50px;
    --LocationAutocompleteInput_sidePaddingDesktop: 36px;

    /* ================ Filters ================ */
    --marketplaceButtonSmallDesktopPadding: 9px 16px 9px 16px;

    /* ================ DateInput, DateRangeInput, DateRangeController ================ */
    --ReactDates_selectionHeight: 36px;
    --ReactDates_hoveredOverlayColor: rgba(255, 255, 255, 0.2);

    --DateInput_selectionHeight: var(--ReactDates_selectionHeight);
    --DateInput_hoveredOverlayColor: var(--ReactDates_hoveredOverlayColor);
    --DateRangeInput_selectionHeight: var(--ReactDates_selectionHeight);
    --DateRangeInput_hoveredOverlayColor: var(--ReactDates_hoveredOverlayColor);
    --DateRangeController_selectionHeight: var(--ReactDates_selectionHeight);

    /* ================ SectionHero ================ */

    --SectionHero_desktopTitleMaxWidth: 625px;

    /* ================ TabNav ================ */
    --TabNav_linkWidth: 240px;

    /* ================ LandingPage ================ */
    --LandingPage_sectionMarginTop: 40px;
    --LandingPage_sectionMarginTopMedium: 60px;
    --LandingPage_sectionMarginTopLarge: 94px;

    /* ================ EditListingAvailabilityForm, ManageAvailabilityCalendar ================ */
    --ManageAvailabilityCalendar_gridColor: #e0e0e0;
    --ManageAvailabilityCalendar_availableColor: #ffffff;
    --ManageAvailabilityCalendar_availableColorHover: #fafafa;
    --ManageAvailabilityCalendar_blockedColor: #ebebeb;
    --ManageAvailabilityCalendar_blockedColorHover: #e6e6e6;
    --ManageAvailabilityCalendar_reservedColor: #e6fff0;
    --ManageAvailabilityCalendar_reservedColorHover: #e1faeb;
    --ManageAvailabilityCalendar_failedColor: #fff2f2;

    /* ================ ProfileSettingsForm ================ */
    --ProfileSettingsForm_avatarSize: 96px;
    --ProfileSettingsForm_avatarSizeDesktop: 240px;
}

/* ================ Global element styles ================ */

body {
    font-family: Helvetica, Arial, sans-serif;
    -webkit-font-smoothing: antialiased; /* http://szafranek.net/blog/2009/02/22/font-smoothing-explained/ */
    -moz-osx-font-smoothing: grayscale; /* http://szafranek.net/blog/2009/02/22/font-smoothing-explained/ */
    text-rendering: optimizeSpeed;

    background-color: var(--matterColorBright);
}

::selection {
    background: var(--marketplaceColor); /* WebKit/Blink Browsers */
    color: var(--matterColorLight);
}

::-moz-selection {
    background: var(--marketplaceColor); /* Gecko Browsers */
    color: var(--matterColorLight);
}

a {
    @apply --marketplaceLinkStyles;
}

h1 {
    @apply --marketplaceH1FontStyles;
}
h2 {
    @apply --marketplaceH2FontStyles;
}
h3 {
    @apply --marketplaceH3FontStyles;
}
h4 {
    @apply --marketplaceH4FontStyles;
}
h5 {
    @apply --marketplaceH5FontStyles;
}
h6 {
    @apply --marketplaceH6FontStyles;
}

input {
    font-family: var(--fontFamily);
    font-weight: var(--fontWeightMedium);
    font-size: 16px;
    line-height: 24px;
    letter-spacing: -0.1px;
    /* No margins for default font */

    @media (--viewportMedium) {
        font-size: 16px;
        line-height: 32px;
    }
}

textarea,
select,
li {
    @apply --marketplaceDefaultFontStyles;
}

p,
pre {
    @apply --marketplaceBodyFontStyles;
}

/* ================ Normalisations ================ */

html {
    @apply --marketplaceDefaultFontStyles;
    color: var(--matterColor);
    padding: 0;
    margin: 0;
}

ul {
    list-style: none;
    padding: 0;
}

legend,
label {
    @apply --marketplaceH5FontStyles;
    font-weight: var(--fontWeightSemiBold);
    display: block;
    margin-top: 0;
    margin-bottom: 0;

    @media (--viewportMedium) {
        font-weight: var(--fontWeightSemiBold);
        margin-top: 0;
        margin-bottom: 0;
        padding-top: 6px;
        padding-bottom: 2px;
    }
}

button {
    font-family: Helvetica, Arial, sans-serif;
}

select {
    @apply --marketplaceSelectStyles;
}

input {
    @apply --marketplaceInputStyles;
}

textarea {
    @apply --marketplaceInputStyles;

    /* Todo: All textareas are using auto-sizing extension which currently calculates required space incorrectly when box-sixing is "border-box" */
    box-sizing: content-box;
    padding: 0 0 10px 0;

    &::placeholder {
        padding: 0 0 10px 0;
    }

    @media (--viewportMedium) {
        padding: 4px 0 10px 0;
    }
}

.fontsLoaded {
    & body {
        font-family: 'poppins', Helvetica, Arial, sans-serif;
        font-weight: 600;
    }
    & button {
        font-family: 'poppins', Helvetica, Arial, sans-serif;
        font-weight: 600;
    }
}

@media only screen and (max-width: 600px) {
    #conversations-wrap {
        display: none !important;
    }
}

@import '../../styles/propertySets.css';

.root {
    margin: 0;
}

.reviewItem {
    margin-bottom: 32px;

    &:last-of-type {
        margin-bottom: 0;
    }

    @media (--viewportMedium) {
        margin-bottom: 39px;
    }
}

.review {
    display: flex;
    flex-direction: row;
    flex-basis: 564px;
    flex-shrink: 1;
    flex-grow: 0;
}

.avatar {
    flex-shrink: 0;
    margin-right: 22px;
}

.reviewContent {
    /* @apply --marketplaceH4FontStyles;
  font-style: italic; */
    margin: 0;
    white-space: pre-line;

    font-family: Poppins;
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
}

.reviewInfo {
    /* display: flex;
  flex-direction: row */
    @apply --marketplaceH5FontStyles;
    margin-top: 10px;
    margin-bottom: 0px;

    color: #b2b2b2;
    font-size: 14px;
    font-weight: 400;

    @media (--viewportMedium) {
        margin-top: 9px;
    }
}

.separator {
    margin: 0 7px;
}

.desktopSeparator {
    margin: 0 7px;
    display: none;

    @media (--viewportMedium) {
        display: inline;
    }
}

.mobileReviewRating {
    display: block;
    margin-top: 3px;
    margin-bottom: 9px;

    @media (--viewportMedium) {
        display: none;
    }
}

.desktopReviewRatingWrapper {
    /* For aligning rating starts in the middle
   * of a paragraph line */
    display: flex;
    position: relative;
    display: none;

    @media (--viewportMedium) {
        display: inline;
    }
}

.desktopReviewRating {
    /* For aligning rating starts in the middle
   * of a paragraph line */
    display: inline-flex;
    position: absolute;
    bottom: 0px;
    white-space: nowrap;
}

.reviewRatingStar {
    height: 12px;
    width: 12px;
    margin-right: 2px;

    &:last-of-type {
        margin-right: 0;
    }
}
